var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"elevation-0"},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"options":_vm.options,"footer-props":{
      'items-per-page-options': _vm.datatables_rowsPerPageItems,
    },"server-items-length":_vm.totalItems},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_vm._v(" Loading items.. ")]},proxy:true},{key:"item.keyword_type",fn:function(ref){
    var item = ref.item;
return [_c('v-icon',[_vm._v(_vm._s(_vm.nkw_getTypeIcon(item.keyword_type)))])]}},{key:"item.deleted_at",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.datatables_formatTimestamp(item.deleted_at))+" ")]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.restore(item.id)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("restore")])],1)]}},{key:"footer.page-text",fn:function(props){return [_vm._v(" "+_vm._s(_vm.datatables_footerPageText(_vm.totalItemsIsEstimate, _vm.estimatedItems, props))+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }