<template>
  <v-card class="elevation-0">
    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      :options.sync="options"
      :footer-props="{
        'items-per-page-options': datatables_rowsPerPageItems,
      }"
      :server-items-length="totalItems"
      class="elevation-0"
    >
      <template v-slot:loading>
        Loading items..
      </template>

      <template v-slot:[`item.keyword_type`]="{ item }">
        <v-icon>{{ nkw_getTypeIcon(item.keyword_type) }}</v-icon>
      </template>

      <template v-slot:[`item.deleted_at`]="{ item }">
        {{ datatables_formatTimestamp(item.deleted_at) }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn icon @click="restore(item.id)">
          <v-icon small>restore</v-icon>
        </v-btn>
      </template>

      <template v-slot:[`footer.page-text`]="props">
        {{ datatables_footerPageText(totalItemsIsEstimate, estimatedItems, props) }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import dataTablesMixin from '@/mixins/datatables'
import nkwMixin from '@/mixins/negativeKeywords'

export default {
  name: 'NKWDeletedNKWsTable',

  data () {
    return {
      headers: [
        { text: 'Keyword', value: 'notated_keyword' },
        { text: 'Type', value: 'keyword_type' },
        { text: 'Deleted at', value: 'deleted_at' },
        { text: 'Deleted by', value: 'deleted_by' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      items: [],
      loading: false,
      totalItems: 0,
      estimatedItems: 0,
      totalItemsIsEstimate: false,
      options: {},
      lsOptions: 'nkwDeletedNKWs_options',
      dataEndpoint: '/a/nkw/keywords-deleted',
      restoreEndpointStem: '/a/nkw/keywords/',
    }
  },

  mixins: [dataTablesMixin, nkwMixin],

  computed: {
  },

  watch: {
    options: {
      handler () {
        this.fetchData()
      },
      deep: true,
    },
  },

  methods: {
    fetchData: function () {
      let myURL = this.dataEndpoint + '?deleted_by_cascade=false&xfields='
      this.loading = true

      // get fields from headers
      myURL = this.datatables_addHeaderFieldsToURI(myURL, this.headers, ['actions'], ['id'])

      // get paging and sorting from options via data table component and save to localStorage
      myURL = this.datatables_processURIOptions(myURL, this.options)
      localStorage.setItem(this.lsOptions, JSON.stringify(this.options))

      this.$http.get(myURL).then(resp => {
        if (resp.data.data) {
          this.items = resp.data.data
          this.totalItemsIsEstimate = resp.headers['x-total-count-estimated'] === 'true' ? true : false
          var itemCounts = this.datatables_getItemCounts(this.totalItemsIsEstimate, Number(resp.headers['x-total-count']))
          this.totalItems = itemCounts.totalItems
          this.estimatedItems = itemCounts.estimatedItems
        } else {
          this.items = []
        }
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'GET', url: myURL, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.loading = false)
    },

    restore: function (id) {
      let myURL = this.restoreEndpointStem + id + '/restore'

      this.$http.post(myURL, null).then(resp => {
        this.$router.push('/negative-keywords/' + id)
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'POST', url: myURL, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally()
    },
  },

  created: function () {
    // get data and refresh regularly to prevent stale data from being shown
    this.timer = setInterval(this.fetchData, this.datatables_refreshIntervalMs)

    // if present, use local sorting/paging options
    if (localStorage.getItem(this.lsOptions)) {
      this.options = JSON.parse(localStorage.getItem(this.lsOptions))
      // setting multisort here: in v-data-table didn't work
      this.options['multiSort'] = true
    } else {
      this.options = {'multiSort': true}
    }
  },

  beforeDestroy () {
    clearInterval(this.timer)
  },
}
</script>
